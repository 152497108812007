/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AuthenticationParameters } from 'msal';
import { fetchMsGraph, GRAPH_REQUESTS, msalApp } from '@/api/auth/authUtils';
import { logger } from '@/utils/logger';
import { ProcessEnvsModule } from '@/store/modules/process-envs.module';

interface LoginService<T> {
  login: () => Promise<T>;
  logout: () => void;
  checkLogin: (token?: string) => Promise<{ token: string; user: T } | undefined>;
  getUser: (token?: string) => Promise<T | undefined>;
}

declare global {
  interface Window {
    azlogout: () => unknown;
  }
}

export interface AzUser {
  displayName: string;
  givenName: string;
  id: string;
  mail: string;
  surname: string;
}

export interface AdoOrg {
  AccountId: string;
  AccountName: string;
}

export default class AzureLoginService implements LoginService<unknown> {
  public logout() {
    msalApp.logout();
  }

  public async getUser(token?: string) {
    const res = await this.checkLogin(token);
    return res?.user;
  }

  public getToken(type: 'ado' | 'ms') {
    if (type === 'ado') {
      return sessionStorage.getItem('adoToken');
    }
    return sessionStorage.getItem('msalToken');
  }
  public setToken(type: 'ado' | 'ms', token: string) {
    if (type === 'ado') {
      return sessionStorage.setItem('adoToken', token);
    }
    return sessionStorage.setItem('msalToken', token);
  }

  protected clearStorage() {
    Object.keys(sessionStorage)
      .filter(key => key.includes('authority'))
      .forEach(key => sessionStorage.removeItem(key));
  }

  public async refreshAdoToken() {
    this.clearStorage();
    return msalApp.acquireTokenSilent(GRAPH_REQUESTS.ADO).then(res => {
      this.setToken('ado', res.accessToken);
      return res.accessToken;
    });
  }

  public async checkLogin(token?: string) {
    logger.debug('checking if we already have a token');
    const t = token || this.getToken('ms');
    if (t) {
      logger.debug('we have a token, checking if it works');
      try {
        const envVars = ProcessEnvsModule.processEnvs;
        const msGraphUrl = `${envVars.msGraphUrl}/me`;
        const user: AzUser = await fetchMsGraph(msGraphUrl, t);
        logger.debug('got a user');
        logger.debug(user);
        return { token: t, user };
      } catch (e) {
        logger.debug('token not valid');
        logger.debug(e);
        return;
      }
    }
    logger.debug('we do not have a token...');
  }

  protected async getTokenPopup(request: AuthenticationParameters) {
    this.clearStorage();
    return await msalApp
      .acquireTokenSilent(request)
      .then(res => {
        logger.debug('silent token acquisition succeeded.');
        logger.debug(res);
        return res;
      })
      .catch(error => {
        logger.debug(error);
        logger.debug('silent token acquisition fails. acquiring token using popup');

        return msalApp
          .acquireTokenPopup(request)
          .then(tokenResponse => {
            logger.debug('Received token response');
            return tokenResponse;
          })
          .catch(error => {
            logger.error(error);
          });
      });
  }

  /**
   * I think this is how it is to maintain separate tokens
   * for ado scope and others, otherwise all the scopes would be in one call
   */
  public async login() {
    let res = await this.checkLogin();
    if (res) {
      return res.user;
    }

    const loginResponse = await msalApp.loginPopup(GRAPH_REQUESTS.LOGIN);

    logger.debug('Login with MSAL is successful!!');
    logger.debug(loginResponse);

    const tokenResponse = await this.getTokenPopup(GRAPH_REQUESTS.LOGIN);

    if (tokenResponse) {
      this.setToken('ms', tokenResponse.accessToken);

      const adoTokenResponse = await this.getTokenPopup(GRAPH_REQUESTS.ADO);
      if (adoTokenResponse) {
        this.setToken('ado', adoTokenResponse.accessToken);
      }
    }

    res = await this.checkLogin();
    if (res) {
      return res.user;
    }
  }

  public getOrganizations(ownerId: string): Promise<AdoOrg[]> {
    const msVsspsUrl = ProcessEnvsModule.processEnvs.msVsspsUrl;
    logger.debug(msVsspsUrl);
    return fetchMsGraph(`${msVsspsUrl}/_apis/accounts?ownerId=${ownerId}`, this.getToken('ado'));
  }

  public canCreateProjectsForOrg(orgName: string, baseUrl: string): Promise<boolean> {
    // these ids seem constant
    // security group for "Collection"
    const securityGroup = '3e65f728-f8bc-4ecd-8764-7e378b19bfa7';
    // bitId for "Create Project" permission
    const bitId = 4;
    const url = `${baseUrl}/${orgName}/_apis/permissions/${securityGroup}/${bitId}?api-version=1.0&token=Namespace%3A`;
    return fetchMsGraph<boolean>(url, this.getToken('ado'));
  }

  public async canCreateProjectsForOrgs(orgIds: string[]): Promise<Record<string, boolean>> {
    const msDevAzureUrl = ProcessEnvsModule.processEnvs.msDevAzureUrl!;
    const result = await Promise.all(
      orgIds.map(orgId => this.canCreateProjectsForOrg(orgId, msDevAzureUrl)),
    );
    const out: Record<string, boolean> = {};

    result.forEach((result, i) => {
      out[orgIds[i]] = result;
    });

    return out;
  }
}
