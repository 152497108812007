import * as Msal from 'msal';
import { AUTH_CONFIG } from '@/api/auth/authConfig';
import axios from '@/api/service';

export const requiresInteraction = (errorMessage: string) => {
  if (!errorMessage || !errorMessage.length) {
    return false;
  }

  return (
    errorMessage.indexOf('consent_required') > -1 ||
    errorMessage.indexOf('interaction_required') > -1 ||
    errorMessage.indexOf('login_required') > -1
  );
};

export const fetchMsGraph = async <T = unknown>(
  url: string,
  accessToken: string | null,
): Promise<T> => {
  let response;
  try {
    response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (e) {
    throw new Error(`Failed to load data at url ${url}: ${response?.status}`);
  }
  return response.data;
};

export const GRAPH_SCOPES = {
  OPENID: 'openid',
  PROFILE: 'profile',
  USER_READ: 'User.Read',
  MAIL_READ: 'Mail.Read',
  ADO_USER_IMPERSONATION: '499b84ac-1321-427f-aa17-267ca6975798/user_impersonation',
};

export const GRAPH_ENDPOINTS = {
  ME: 'https://graph.microsoft.com/v1.0/me',
  MAIL: 'https://graph.microsoft.com/v1.0/me/messages',
};

export const GRAPH_REQUESTS = {
  LOGIN: {
    scopes: [GRAPH_SCOPES.OPENID, GRAPH_SCOPES.PROFILE, GRAPH_SCOPES.USER_READ],
  },
  EMAIL: {
    scopes: [GRAPH_SCOPES.MAIL_READ],
  },
  ADO: { scopes: [GRAPH_SCOPES.ADO_USER_IMPERSONATION] },
};

export const msalApp = new Msal.UserAgentApplication({
  auth: {
    clientId: AUTH_CONFIG.clientId,
    authority: `https://login.microsoftonline.com/${AUTH_CONFIG.tenantId}`,
    validateAuthority: false,
    navigateToLoginRequestUrl: false,
    redirectUri: location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    loadFrameTimeout: 30000,
  },
});
