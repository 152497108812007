
import { Vue, Component, Watch } from 'vue-property-decorator';
import gitHubLoginService, { GitHubUser } from '@/components/github-auth/gitHubLoginService';
import GButton from '@/components/gsk-components/GskButton.vue';
import UserAvatar from '@/components/UserCircle.vue';
import { BaseUser } from '@/types/users.types';

@Component({
  components: {
    GButton,
    UserAvatar,
  },
})
export default class GitHubLogin extends Vue {
  authService = gitHubLoginService;
  error = '';
  user: null | GitHubUser = null;

  @Watch('user', { immediate: true })
  handleLogin() {
    if (this.user) {
      this.$emit('login', this.user);
    } else {
      this.$emit('logout');
    }
  }

  @Watch('error')
  handleError(error: string) {
    if (error) {
      this.$emit('error', error);
    }
  }

  async created() {
    const res = await this.authService.checkLogin();
    if (res) {
      this.user = res.user;
    }
  }

  get avatarInfo(): BaseUser {
    return {
      mudId: this.user?.login ?? '',
      email: this.user?.email ?? '',
      fullName: this.user?.name,
    };
  }

  async signIn() {
    try {
      const res = await this.authService.login();
      this.user = res || null;
    } catch (e) {
      this.error = e;
      this.user = null;
    }
  }
  async signOut() {
    this.authService.logout();
    this.user = null;
  }
}
