import { RestEndpointMethodTypes, Octokit } from '@octokit/rest';
import { logger } from '@/utils/logger';
import { ProcessEnvsModule } from '@/store/modules/process-envs.module';

export type GitHubUser = RestEndpointMethodTypes['users']['getAuthenticated']['response']['data'];

interface LoginService<T> {
  login: () => Promise<T>;
  logout: () => void;
  checkLogin: (token?: string) => Promise<{ token: string; user: T } | undefined>;
  getUser: (token?: string) => Promise<T | undefined>;
  getToken: () => string | null;
  setToken: (token: string) => void;
}

class GitHubLoginService implements LoginService<GitHubUser> {
  protected w: Window | null = null;
  protected intervalId: number | null = null;

  public get octokit(): Octokit | null {
    const githubApiUrl = ProcessEnvsModule.processEnvs.githubApiUrl;
    const auth = this.getToken();
    if (auth) {
      return new Octokit({
        baseUrl: githubApiUrl,
        auth,
      });
    }

    return null;
  }

  public getToken() {
    return sessionStorage.getItem('gitHubToken');
  }
  public setToken(token: string) {
    return sessionStorage.setItem('gitHubToken', token);
  }

  public async login() {
    const res = await this.checkLogin();
    if (res) {
      return res.user;
    }
    logger.debug('Opening GitHub auth window');
    this.w = window.open(
      '/api/proxy/github/login',
      'gh-auth',
      'resizable,scrollbars,status,width=800,height=600',
    );
    logger.debug(this.w);
    return this.poll();
  }

  public logout() {
    sessionStorage.removeItem('gitHubToken');
  }

  protected poll(): Promise<GitHubUser> {
    return new Promise((resolve, reject) => {
      this.intervalId = window.setInterval(() => {
        const popup = this.w;
        if (!popup || popup.closed) {
          this.close();
          reject(new Error('The popup was closed'));
          return;
        }

        if (popup.location.pathname !== '/api/proxy/github/creds') {
          return;
        }
        const c = new URLSearchParams(popup.location.search).get('c');
        if (c) {
          const creds = JSON.parse(c);
          if (creds.error) {
            reject(new Error(creds.error));
          } else {
            this.setToken(creds.token);
            this.getUser(creds.token).then(r => {
              if (r) {
                resolve(r);
              } else {
                reject(new Error('Unexpected Error: Could not resolve GitHub user'));
              }
            });
          }
        } else {
          reject(new Error('Unexpected Error: did not receive credentials'));
        }
        this.close();
      }, 500);
    });
  }

  /**
   * if logged in returns a user & token, otherwise null
   */
  public async checkLogin(token?: string) {
    const githubApiUrl = ProcessEnvsModule.processEnvs.githubApiUrl;
    logger.debug('checking if we already have a token');
    const t = token || this.getToken();
    if (t) {
      logger.debug('we have a token, checking if it works');
      const octokit = new Octokit({
        baseUrl: githubApiUrl,
        auth: t,
      });
      try {
        const res = await octokit.users.getAuthenticated();
        logger.debug('got a user');
        logger.debug(res.data);
        return { token: t, user: res.data };
      } catch (e) {
        logger.debug('token not valid');
        logger.debug(e);
      }
    }
  }

  public async getUser(token?: string) {
    const res = await this.checkLogin(token);
    return res?.user;
  }

  public close() {
    this.cancel();
    this.w?.close();
  }
  public cancel() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }
}

export default new GitHubLoginService();
