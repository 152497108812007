
import { Vue, Component, Watch } from 'vue-property-decorator';
import GButton from '@/components/gsk-components/GskButton.vue';
import UserAvatar from '@/components/UserCircle.vue';
import { BaseUser } from '@/types/users.types';
import AzureLoginService, { AzUser } from '@/components/Auth/auth.service';
import { UserModule } from '@/store/modules/user.module';
import GDialog from '@/components/gsk-components/GskDialog.vue';

@Component({
  components: {
    GButton,
    UserAvatar,
    GDialog,
  },
})
export default class AzLogin extends Vue {
  authService = new AzureLoginService();
  error = '';
  user: null | AzUser = null;

  @Watch('user', { immediate: true })
  handleLogin() {
    if (this.user) {
      this.$emit('login', this.user);
    } else {
      this.$emit('logout');
    }
  }

  @Watch('error')
  handleError(error: string) {
    if (error) {
      this.$emit('error', error);
    }
  }

  unsetUser() {
    this.user = null;
    this.$emit('logout');
  }

  async created() {
    const res = await this.authService.checkLogin();
    if (res) {
      this.user = res.user;
    }
    this.$root.$on('azLogout', this.unsetUser);
    window.azlogout = () => this.$root.$emit('azLogout');
  }

  beforeDestroy() {
    this.$root.$off('azLogout', this.unsetUser);
  }


  get avatarInfo(): BaseUser {
    return {
      mudId: UserModule.user.email === this.user?.mail ? UserModule.user.mudId : '',
      email: this.user?.mail ?? '',
      fullName: this.user?.displayName,
    };
  }

  async signIn() {
    this.$emit('loading', true);
    try {
      const res = await this.authService.login();
      this.user = res || null;
    } catch (e) {
      this.error = e;
      this.user = null;
    }
    this.$emit('loading', false);
  }

  dialogOpen = false;
  confirmSignOut() {
    this.dialogOpen = true;
  }
  async signOut() {
    this.authService.logout();
    this.user = null;
    this.$emit('logout');
  }
}
